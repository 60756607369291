import React, { useState } from "react";
import NewStyleModal from "../NewStyleModal";
import { format } from "date-fns";
import styles from "./styles.module.scss";
import LilliButton from "../DesignSystemComponents/LilliButton/LilliButton";
import ResolveFallForm from "../../Forms/ResolveFallForm";
import { postHubFallEvent } from "@intelligentlilli/api-layer";
import { SERVER } from "../../Services/config";

const FallResolutionModal = ({
  setShowAnimation,
  showAnimation,
  fallDetectedModalData,
}) => {
  const startTime =
    fallDetectedModalData.events[fallDetectedModalData.events.length - 1]
      .timestamp;
  const prettyStartTime = format(new Date(startTime), "HH:mm");

  const [falseAlarm, setFalseAlarm] = useState(false);

  const handleFallResolved = async (values) => {
    console.log("values", values);
    const updateFallResponse = await postHubFallEvent({
      server: SERVER,
      hubId: 1,
      fallId: fallDetectedModalData.id,
      fallEvent: { status: "resolved" },
      origin: "web",
    });
    console.log("updateFallResponse", updateFallResponse);
    // TODO Falls - handle failed responses and show some UI
    // TODO Falls - handle successful responses and immediate state update for UI
    // TODO Falls - Align the form data to the metadata schema and send it as JSON once the API is ready
    setShowAnimation(false);
  };
  return (
    <NewStyleModal
      showCloseIcon={true}
      hide={() => {
        setShowAnimation(false);
      }}
      useFade={true}
      showAnimation={showAnimation}
    >
      <div className={styles.container}>
        {falseAlarm && (
          <>
            <h1>Set as false alarm?</h1>
            <p>
              You've selected 'False alarm'. This means no fall occurred, and no
              further action will be recorded for this incident.
            </p>
            <div className={styles.spacer} />
            <p>Are you sure you want to proceed?</p>
            <div className={styles.spacer} />
            <LilliButton>Set as false alarm</LilliButton>
            <div className={styles.spacer} />
            <LilliButton
              variant="secondary"
              onClick={() => setFalseAlarm(false)}
            >
              Go back
            </LilliButton>
            <div className={styles.spacer} />
          </>
        )}
        {!falseAlarm && (
          <>
            <h1>Take action on fall: Bedroom, Room 82</h1>
            <p>Time of fall: {prettyStartTime}</p>
            <div className={styles.spacer} />
            <ResolveFallForm onSubmit={handleFallResolved} />

            <div className={styles.spacer} />
          </>
        )}
      </div>
    </NewStyleModal>
  );
};

export default FallResolutionModal;
