import { differenceInHours, differenceInDays } from "date-fns";

const isOlderThan48Hours = (timestamp) => {
  const now = new Date();
  const fallTime = new Date(timestamp);
  const diffInHours = differenceInHours(now, fallTime);
  return diffInHours > 24;
};

export const isLessThanEqualTo7DaysOld = (timestamp) => {
  const now = new Date();
  const fallTime = new Date(timestamp);
  const diffInDays = differenceInDays(now, fallTime);
  return diffInDays <= 7;
};

export const groupFallsData = (serviceUsersData) => {
  const liveFalls = [];
  const recentFalls = [];
  const otherUsers = [];

  if (!serviceUsersData || typeof serviceUsersData !== "object") {
    return { liveFalls, recentFalls, otherUsers };
  }

  Object.entries(serviceUsersData).forEach(([hubId, userData]) => {
    if (!userData || !userData.falls || userData.falls.length === 0) {
      return;
    }

    const fall = userData.falls[userData.falls.length - 1];
    if (!fall || typeof fall !== "object") {
      return;
    }

    const { currentStatus, timestamp } = fall;

    if (currentStatus === "detected") {
      liveFalls.push(fall);
    } else if (timestamp && !isOlderThan48Hours(timestamp)) {
      recentFalls.push(fall);
    } else {
      otherUsers.push(fall);
    }
  });

  return { liveFalls, recentFalls, otherUsers };
};
