import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styles from "./styles.module.scss";
// Components
import Page from "../../Components/Page";
import FallsDashboardHeader from "../../Components/FallsDashboardHeader";
import FallCard from "../../Components/FallCard";
import Line from "../../Components/Line";
import FallDetectedModal from "../../Components/FallDetectedModal";
import FallResolutionModal from "../../Components/FallResolutionModal";
// Hooks
import { useFade } from "../../Services/Hooks";
// Utils
import LogRocket from "logrocket";
import { groupFallsData } from "../../Services/Utils/";
// Dummy data
// import { suData } from "../../Services/Utils/dummy_falls_data";

const FallsDashboard = () => {
  const user = useSelector((state) => state.user);
  // const serviceUsersData = suData; // TO DO FALLS: remove when pushing to PROD
  const serviceUsersData = useSelector((state) => state.serviceUsersData);

  const [fallDetectedModalData, setFallDetectedModalData] = useState(null);
  const [modalType, setModalType] = useState(null);

  useEffect(() => {
    if (user.key && user.forenames) {
      LogRocket.identify(user.key, {
        name: user.forenames,
        organisationId: user.organisationId,
        isAdmin: user.roles?.includes("admin"),
        isManager:
          user.roles?.includes("manager") && !user.roles?.includes("admin"),
        isStaff:
          user.roles?.includes("user") &&
          !user.roles?.includes("manager") &&
          !user.roles?.includes("admin"),
        isInstallerOnly:
          user.roles?.includes("installer") &&
          !user.roles?.includes("user") &&
          !user.roles?.includes("manager") &&
          !user.roles?.includes("admin"),
        hasInstallerPermissions:
          user.roles?.includes("installer") && !user.roles?.includes("admin"),
      });
    }
  }, [user.key, user.hubs, user.organisationId, user.roles, user.forenames]);

  const [isVisible, setShowAnimation, showAnimation] = useFade(false, 150); // make sure this is in sync with the NewStyleModal fade_out transition, which is currently set to 200ms. We want the setTimeout to remove the modal before the fade_out transition ends so that we don't deal with the double flash of the modal (perhaps due to race conditions, its unclear)

  const { liveFalls, recentFalls, otherUsers } =
    groupFallsData(serviceUsersData);

  return (
    <Page className={styles.page}>
      <div className={styles.content}>
        <FallsDashboardHeader />
        <h2>Live falls</h2>
        {liveFalls.length === 0 ? (
          <div className={styles.no_falls}>
            <h3>No current falls</h3>
          </div>
        ) : (
          <div className={styles.section}>
            {liveFalls.map((fall, index) => (
              <FallCard
                key={index}
                fall={fall}
                onClick={() => {
                  setFallDetectedModalData(fall);
                  setShowAnimation(true);
                  setModalType("respond");
                }}
              />
            ))}
          </div>
        )}
        {recentFalls.length > 0 && (
          <>
            <Line className={styles.line} />
            <h2>Falls in the past 7 days</h2>
            <div className={styles.section}>
              {recentFalls.map((fall, index) => (
                <FallCard
                  key={index}
                  fall={fall}
                  onClick={
                    fall.currentStatus !== "resolved"
                      ? () => {
                          setFallDetectedModalData(fall);
                          setShowAnimation(true);
                          setModalType("resolve");
                        }
                      : null
                  }
                />
              ))}
            </div>
          </>
        )}
        <Line className={styles.line} />
        <h2>All other falls</h2>
        <div className={styles.section}>
          {otherUsers.map((fall, index) => (
            <FallCard
              key={index}
              fall={fall}
              onClick={
                fall.currentStatus !== "resolved"
                  ? () => {
                      setFallDetectedModalData(fall);
                      setShowAnimation(true);
                    }
                  : null
              }
            />
          ))}
        </div>
      </div>
      {/* Modal: Fall detected */}
      {isVisible && modalType === "respond" && (
        <FallDetectedModal
          showAnimation={showAnimation}
          setShowAnimation={setShowAnimation}
          fallDetectedModalData={fallDetectedModalData}
        />
      )}
      {/* Modal: Fall detected */}
      {isVisible && modalType === "resolve" && (
        <FallResolutionModal
          showAnimation={showAnimation}
          setShowAnimation={setShowAnimation}
          fallDetectedModalData={fallDetectedModalData}
        />
      )}
    </Page>
  );
};

export default FallsDashboard;
