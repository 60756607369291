import React from "react";
import styles from "./styles.module.scss";
import {
  ArrowCircleRightIcon,
  AttendingIcon,
  BathroomIcon,
  BedIcon,
  FallIcon,
} from "../../Styles/Icons/DesignSystem";
import theme from "../../Styles/theme.scss";
import { format, isSameDay } from "date-fns";
import classNames from "classnames";
import { isLessThanEqualTo7DaysOld } from "../../Services/Utils";

const FallCard = ({ fall, onClick }) => {
  const [hovering, setHovering] = React.useState(false);
  const [pressing, setPressing] = React.useState(false);

  const detected = fall.currentStatus === "detected";
  const acknowledged = fall.currentStatus === "acknowledged";
  const resolved = fall.currentStatus === "resolved";

  const fallEvent = fall.events.filter((i) => i.status === "detected");
  const timeOfFall = fallEvent[0].timestamp;

  const getBorderColour = (type) => {
    switch (type) {
      case "detected":
        return theme.risk3;
      case "acknowledged":
        return theme.unexpected2;
      default:
        return theme.neutral2;
    }
  };
  const getCardColour = (fall, room) => {
    if (
      fall.location !== room ||
      !fall.location ||
      fall.currentStatus === "resolved"
    ) {
      return theme.neutral2;
    } else if (fall.currentStatus === "detected") {
      return theme.risk3;
    } else {
      return theme.expected1;
    }
  };

  const getFallTime = (ts) => {
    const date = new Date(ts);
    if (isSameDay(date, new Date())) {
      return `${format(new Date(date), "H:mm")} Today`;
    } else if (!isLessThanEqualTo7DaysOld(date)) {
      return `${format(new Date(date), "H:mm • dd/MM/yy")}`;
    } else {
      return `${format(new Date(date), "H:mm")} on ${format(new Date(date), "d MMM")}`;
    }
  };

  return (
    <button
      className={`${resolved ? styles.card_resolved : styles.card} ${detected ? styles.card_detected : ""}`}
      style={{ border: `2px solid ${getBorderColour(fall.currentStatus)}` }}
      onMouseOver={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
      // onPress style change
      onMouseDown={() => setPressing(true)}
      onMouseUp={() => setPressing(false)}
      onClick={onClick}
    >
      <div className={styles.row}>
        <h3>Hub Id: {fall.deviceId}</h3>
        {detected && (
          <div className={styles.fall_icon}>
            <FallIcon />
            <div>Fall detected</div>
          </div>
        )}
        {acknowledged && (
          <div className={styles.acknowledged_icon}>
            <AttendingIcon />
            <div>Attending</div>
          </div>
        )}
      </div>
      <div className={styles.row}>
        <div
          className={styles.sensor}
          style={{ backgroundColor: getCardColour(fall, "Bedroom") }}
        >
          <div>Bedroom</div>
          <BedIcon />
          <h4>{fall.location === "Bedroom" ? "Fall" : ""}</h4>
        </div>
        <div
          className={classNames(styles.sensor, {
            [styles.fall_in_progress]: detected,
            [styles.fall_attended]: acknowledged,
          })}
          style={{ backgroundColor: getCardColour(fall, "Bathroom") }}
        >
          <div>Bathroom</div>
          <BathroomIcon />
          <h4>{fall.location === "Bathroom" ? "Fall" : ""}</h4>
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.row_element}>
          <FallIcon width={20} />
          <p className={styles.time_of_fall}>{getFallTime(timeOfFall)}</p>
        </div>
        {!resolved && (
          <div
            className={`${hovering ? styles.action_hover : styles.action} ${pressing ? styles.action_pressing : styles.action}`}
          >
            {detected ? "Take action" : "View details"}
            <ArrowCircleRightIcon />
          </div>
        )}
      </div>
    </button>
  );
};

export default FallCard;
