import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getHubFalls } from "@intelligentlilli/api-layer";
import { SERVER } from "../config";
// State
import { updateServiceUserData } from "../../State/slices/serviceUsersData-slice";
// Utils
import { areArraysEqualById } from "../../Services/Utils/";

export const useFetchFalls = (isFalls) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const serviceUsers = useSelector(
    (state) => state.serviceUsers,
    (prev, next) => areArraysEqualById(prev, next) // custom equality check
  );

  // Memoize serviceUserIds
  const serviceUserIds = serviceUsers.map((su) => su.id); // TO DO FALLS: remove when pushing to PROD
  const stringifiedIds = JSON.stringify(serviceUserIds);

  useEffect(() => {
    const arrayOfIDs = JSON.parse(stringifiedIds);

    if (!isFalls && (!arrayOfIDs || arrayOfIDs.length === 0)) return;

    const fetchFalls = async (suIDs) => {
      ["1"]?.map(async (hubId) => {
        // TODO FALLS: remove hardcoded hubId
        try {
          const hubFallsData = await getHubFalls({
            server: SERVER,
            hubId,
            origin: "web",
          });
          if (hubFallsData.ok && hubFallsData?.body) {
            dispatch(
              updateServiceUserData({
                hubId: 599, // TODO FALLS: remove hardcoded hubId
                update: {
                  falls: hubFallsData.body,
                },
              })
            );
          } else {
            console.error("Res not okay for getHubFalls, hubId:", hubId);
          }
        } catch (error) {
          if (error.status === 401) {
            return navigate("/login");
          } else {
            console.error("Error with getHubFalls:", error);
          }
        }
      });
    };

    fetchFalls(arrayOfIDs);

    const interval = window.setInterval(() => {
      fetchFalls(arrayOfIDs);
      console.log("fetched falls");
    }, 15000); // every 15 seconds

    // Remove polling when the component unmounts
    return () => interval && window.clearInterval(interval);
  }, [stringifiedIds, isFalls, navigate, dispatch]);
};
